<template>
  <div>
    <v-card>
      <v-card-title>
        Objekty
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="properties"
        class="elevation-1"
        :loading="runningRequest"
        :server-items-length="totalProperties"
        :options.sync="paginationOptions"
        :footer-props="{'items-per-page-options': [10, 20, 50] }"
      >
        <template v-slot:item.approvalProcessState="{ item }">
          <v-chip
            :color="getColor(item.approvalProcessState)"
            dark
          >
            {{ getApprovalProcessStateText(item.approvalProcessState) }}
          </v-chip>
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | localizedDate }}
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <span v-if="item.updatedAt">{{ item.updatedAt | localizedDate }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:item.state="{ item }">
          <v-chip
            :color="getColor(item.state)"
            dark
          >
            {{ item.state }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="changeApprovalProcessState(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
          >
            mdi-delete
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="redirectToDetail(item)"
          >
            mdi-information-variant
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      width="450"
      v-model="editDialog">
      <ApprovalProcessStateForm
        :property="editedProperty"
        v-if="editDialog"
        @edit-success="showApprovalProcessStatesChangeSuccess"
        @edit-failure="showApprovalProcessStatesChangeFailure"
      />
    </v-dialog>
    <v-dialog
      width="350"
      v-model="deleteDialog">
      <v-card>
        <v-card-title class="text-center">Opravdu smazat?</v-card-title>
        <v-card-actions class="d-flex justify-center ">
          <v-btn
            width="250"
            color="red"
            class="rounded-0">
            Smazat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApprovalProcessStateForm from '@/components/demands/ApprovalProcessStateForm'
import propertiesOverviewHelpers from '@/mixins/local/propertiesOverviewHelpers'
export default {
  name: 'PropertiesOverview',
  components: { ApprovalProcessStateForm },
  mixins: [propertiesOverviewHelpers],
  data () {
    return {
      deleteDialog: false,
      headers: [
        {
          text: 'Název',
          align: 'start',
          value: 'name'
        },
        { text: 'Stav', value: 'approvalProcessState' },
        { text: 'Vytvořeno', value: 'createdAt' },
        { text: 'Poslední editace', value: 'updatedAt' },
        { text: 'Hodnoceni', value: 'rating' },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      properties: [],
      totalProperties: 0,
      runningRequest: false
    }
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadProperties()
      }
    }
  },
  computed: {
    ...mapGetters({
      host: 'detailViews/host'
    })
  },
  methods: {
    getProperties () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions

      this.$http.get(`/hosts/${this.host.id}/properties`, {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.properties = res.data.properties
          this.totalProperties = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    redirectToDetail (property) {
      this.$router.push(`/admin/properties/${property.id}`)
    }
  },
  mounted () {
    this.loadProperties = this.debounce(this.getProperties, 1500)
  }
}
</script>

<style scoped>

</style>
