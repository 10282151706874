<template>
  <v-card>
    <v-container class="px-5" v-if="!loading">
      <div class="display-1 text-center">{{ host.deletedAt ? 'Profil hostitele' : 'Úprava profilu hostitele' }}</div>
      <v-row class="mt-4">
        <v-col cols="12" class="d-flex justify-end">
          <v-switch
            v-if="!host.deletedAt"
            v-model="editEnabled"
            label="Povolit editaci"
            dense
          >
          </v-switch>
        </v-col>
      </v-row>
      <v-form :disabled="!editEnabled" >
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="form.email"
            type="email"
            label="Email*"
            :rules="[validation.required, validation.email]"
            outlined
            dense
            class="rounded-0"
            required>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.nickname"
            :rules="[validation.required]"
            type="text"
            label="Přezdívka*"
            outlined
            dense
            class="rounded-0"
            required>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.password"
            :rules="[ validation.minLength(6) ]"
            type="password"
            label="Heslo*"
            outlined
            dense
            class="rounded-0"
            required>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.passwordConfirmation"
            type="password"
            label="Heslo znovu*"
            :rules="form.password ? [validation.equal(form.password)] : []"
            outlined
            dense
            class="rounded-0"
            required>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.firstName"
            type="text"
            label="Jméno"
            outlined
            dense
            class="rounded-0"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.lastName"
            type="text"
            label="Příjmení"
            outlined
            dense
            class="rounded-0"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model.trim="form.companyName"
            type="text"
            label="Název firmy"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.ico"
            type="number"
            label="IČO"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.dic"
            type="text"
            label="DIČ"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model.trim="form.street"
            type="text"
            label="Adresa"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model.trim="form.streetNumber"
            type="text"
            label="Číslo popisné"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model.trim="form.city"
            type="text"
            label="Město"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model.trim="form.zip"
            type="text"
            label="PSČ"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.phone"
            type="tel"
            label="Telefon"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.phone2"
            type="tel"
            label="Druhý telefon"
            outlined
            dense
            class="rounded-0"
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-btn
            color="primary"
            class="rounded-0"
            @click="save()"
            v-if="!host.deletedAt"
            :disabled="!formValid || !editEnabled"
          >Upravit</v-btn>
        </v-col>
      </v-row>
      </v-form>
    </v-container>
    <v-skeleton-loader v-else type="article" />
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'HostForm',
  data () {
    return {
      editEnabled: false,
      form: {},
      runningRequest: false,
      formValid: true
    }
  },
  methods: {
    save () {
      this.clearAlerts()
      this.runningRequest = true
      const updatePromise = this.$http.put(`/admin/hosts/${this.host.id}`, {
        ...this.form,
        password: this.form.password || null,
        passwordConfirmation: this.form.passwordConfirmation || null
      })

      updatePromise
        .then(res => {
          this.host = res.data
          this.setAlerts([{ message: 'Úprava hostitele proběhla úspěšně', type: 'success' }])
          this.editEnabled = false
        })
        .catch(err => {
          if (err.response.data === 'Email already used') {
            this.clearAlerts()
            this.setAlerts([{ message: 'E-mail je již používán', type: 'error' }])
          }
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    ...mapMutations({
      clearAlerts: 'alerts/clearAlerts'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    loading () {
      return !Object.keys(this.host).length || this.runningRequest
    },
    host: {
      get () {
        return this.$store.state.detailViews.host
      },
      set (host) {
        this.$store.state.detailViews.host = host
      }
    }
  },
  watch: {
    host () {
      const host = this.host
      this.form = {
        ...host,
        password: '',
        passwordConfirmation: ''
      }

      delete this.form.id
      delete this.form.credit
      delete this.form.propertiesCount
    }
  }
}
</script>

<style scoped>

</style>
