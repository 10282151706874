<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>
        Informace
      </v-tab>
      <v-tab>
        Objekty
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <HostForm></HostForm>
      </v-tab-item>
      <v-tab-item>
        <PropertiesOverview></PropertiesOverview>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import HostForm from '../../components/hosts/HostForm'
import PropertiesOverview from '../../components/hosts/PropertiesOverview'
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'HostDetail',
  components: {
    PropertiesOverview,
    HostForm
  },
  data () {
    return {
      tab: 0
    }
  },
  methods: {
    ...mapMutations({
      setHost: 'detailViews/setHost'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  created () {
    this.$http.get(`/hosts/${this.$route.params.id}`)
      .then(res => {
        this.setHost(res.data)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.setAlerts([
            { message: 'Hostitel nenalezen', type: 'error' }
          ])
        }

        if (err.response.data.message === 'NOT_A_HOST') {
          this.setAlerts([])
          this.$router.replace({
            name: 'admin.users.user',
            params: {
              id: this.$route.params.id
            }
          })
        }
      })
  },
  beforeDestroy () {
    this.setHost({})
  }
}
</script>

<style scoped>

</style>
