var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Objekty "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.properties,"loading":_vm.runningRequest,"server-items-length":_vm.totalProperties,"options":_vm.paginationOptions,"footer-props":{'items-per-page-options': [10, 20, 50] }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.approvalProcessState",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.approvalProcessState),"dark":""}},[_vm._v(" "+_vm._s(_vm.getApprovalProcessStateText(item.approvalProcessState))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localizedDate")(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [(item.updatedAt)?_c('span',[_vm._v(_vm._s(_vm._f("localizedDate")(item.updatedAt)))]):_c('span',[_vm._v("-")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.state),"dark":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.changeApprovalProcessState(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.redirectToDetail(item)}}},[_vm._v(" mdi-information-variant ")])]}}])})],1),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[(_vm.editDialog)?_c('ApprovalProcessStateForm',{attrs:{"property":_vm.editedProperty},on:{"edit-success":_vm.showApprovalProcessStatesChangeSuccess,"edit-failure":_vm.showApprovalProcessStatesChangeFailure}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center"},[_vm._v("Opravdu smazat?")]),_c('v-card-actions',{staticClass:"d-flex justify-center "},[_c('v-btn',{staticClass:"rounded-0",attrs:{"width":"250","color":"red"}},[_vm._v(" Smazat ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }